import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../AppContext";
import { formatDate, secureFetch } from "../util/flip_utils";
import SearchSelect from "./SearchSelect";
import TableList from "./TableList";


const EditPaymentForm = ({formData, closeModal, submitForm}) => {
    let context = useContext(AppContext);
    let history = useHistory();
    let [payment, setPayment] = useState(formData);
    let [payMethods, setPayMethods] = useState([]);
    let [statuses, setStatuses] = useState([]);
    let [payTypes, setPayTypes] = useState([]);
    let [lineItems, setLineItems] = useState([]);
    let [lineItemTypes, setLineItemTypes] = useState([]);
    let [adjustment, setAdjustment] = useState({amount: formData?.amount, payment_id: formData?.id, reason: `\nOld Notes:\n${formData?.notes}`})
    let [adjustments, setAdjustments] = useState([])
    let [formDisable, setFormDisable] = useState(false);


    function fetchOptions() {
        secureFetch(context.server + '/get-all', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'payment_methods', params: {
                equal: [{
                    key: 'active',
                    value: '1',
                }, {
                    key: 'rma',
                    value: formData?.tpa_id > 0
                }, {
                    key: 'ra',
                    value: formData?.ra_id > 0
                }]
            }})
        })
        .then(resp => resp.json())
        .then(results => setPayMethods(results?.filter(pm => pm?.active==1)))

        secureFetch(context.server + '/get-all', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'payment_statuses'})
        })
        .then(resp => resp.json())
        .then(results => setStatuses(results))

        secureFetch(context.server + '/get-all', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'payment_types'})
        })
        .then(resp => resp.json())
        .then(results => setPayTypes(results));

        secureFetch(context.server + '/get-all', {
            credentials: 'include',
            method: 'POST',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({tableName: 'line_item_types'})
        })
        .then(resp => resp.json())
        .then(results => setLineItemTypes(results))
    }

    function fetchAdjustments() {
        secureFetch(context.server + '/payment-adjustments', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({
                equal: [{
                    key: 'payment_id',
                    value: formData?.id
                }],
                sort: {
                    key: 'created',
                    order: -1
                }
            })
        })
        .then(resp => resp.json())
        .then(results => setAdjustments(results))
    }

    function fetchLineItems() {
        if(!payment?.id) return;
        secureFetch(context.server + '/payment-line-items', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({id: formData?.id})
        })
        .then(resp => resp.json())
        .then(results => setLineItems(results?.map(r => {return {...r, readOnly: 1}})))
    }

    useEffect(() => {
        fetchOptions();
        fetchAdjustments();
        fetchLineItems();
    }, [])

    useEffect(() => {
        setAdjustment({...adjustment, amount: lineItems.map(l => l?.amount).reduce((acc, n) => {return acc + parseFloat(n)}, 0)})
    }, [lineItems])


    function submit(e) {
        e.preventDefault();
        setFormDisable(true);
        secureFetch(context.server + '/adjust-payment', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({
                payment: payment,
                adjustment: adjustment,
                line_items: lineItems
            })
        })
        .then(() => {
            submitForm();
            alert('Payment adjustment made!')
            closeModal();
        })

        
    }

    function cancelPayment(e) {
        e.preventDefault();
        secureFetch(context?.server + '/update-payment-fields', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({
                id: payment?.id,
                keys: ['active'],
                values: ['0'],
                message: 'user cancelled payment'
            })
        })
        .then(() => {
            closeModal();
            history?.push({pathname: '/outgoing-payments'});
        })
    }

    return (
        <div>
            <form id='EditPaymentForm' onSubmit={submit} />
            <fieldset form='EditPaymentForm' disabled={formDisable ? 'disabled' : ''}>
                <div className='bg-light' style={{padding: '15px 15px'}}>
                <h1 className='dark-text'>Edit Payment Info{payment?.title ? ': ' + payment?.title : ''}</h1>
                <label className='dark-text text-left'>Payment Method:</label>

                {/*<SearchSelect options={payMethods.map(row => { return {label: row?.payment_method, value: row?.id} })}
                initialValue={{label: payment?.payment_method, value: payment?.payment_method_id}} updateParent={opt => {
                    opt ? setPayment({...payment, payment_method_id: opt?.value})
                    : setPayment({...payment, payment_method_id: null})
                }} />*/}
                <select form='EditPaymentForm' required className='contained' onChange={ e => setPayment({...payment, payment_method_id: e.target.value}) } value={payment?.payment_method_id}>
                    <option value={null} key={0}>*** SELECT PAYMENT METHOD ***</option>
                    {payMethods.map( (row) => 
                        <option key={row?.id} value={row?.id} >{row?.payment_method}</option>
                    )}
                </select>

                {payment?.ra_id > 0 ? <>
                <label className='dark-text text-left'>RA Type:</label>
                <select form='EditPaymentForm' required className='contained' onChange={ e => setPayment({...payment, payment_type_id: e.target.value}) } value={payment?.payment_type_id}>
                    <option value={null} key={0}>*** SELECT RA TYPE ***</option>
                    {payTypes.map( (row) => 
                        <option key={row?.id} value={row?.id} >{row?.payment_type}</option>
                    )}
                </select>
                </> : <>
                <label className='dark-text text-left'>Payment Type:</label>
                <input type='text' disabled value={payment?.is_consignment == 1 || payment?.sale_type_id == 4 ? "Consignment" : "Buy/Trade"} />
                </>}

                <label className='dark-text text-left'>Amounts:</label>
                {lineItems.map((row, ind) =>
                    <li className="container">
                        <label className="contained dark-text text-left">Description:&nbsp;&nbsp;&nbsp;
                        {payment?.ra_id ?
                            <select form='EditPaymentForm' required value={row?.line_item_type_id} onChange={e => {
                                let lines = lineItems.map(l=>l);
                                lines[ind] = {...row, line_item_type_id: e.target.value}
                                setLineItems(lines);
                            }}>
                                {[<option key={-1} value={null}>-</option>, ...lineItemTypes?.map((liType) =>
                                    <option key={liType?.id} value={liType?.id}>{liType?.line_item_type}</option>
                                )]}
                            </select>
                        :
                            <input form='EditPaymentForm' required disabled={row?.readOnly ? 'disabled': ''} value={row?.title} onChange={e => {
                                let lines = lineItems.map(l=>l);
                                lines[ind] = {...row, title: e.target.value}
                                setLineItems(lines);
                            }} />
                        }
                        </label>
                        <label className="contained dark-text text-left">Amount:{row?.line_item_type_id==9 ? <i className="red-text">&nbsp;(-)</i> : <></>}&nbsp;&nbsp;&nbsp;
                        <input form='EditPaymentForm' type='number' required value={row?.amount} onChange={e => {
                            let lines = lineItems.map(l=>l);
                            lines[ind] = {...row, amount: e.target.value}
                            setLineItems(lines);
                        }} /></label>
                        <label><button className="btn bg-red light-text" style={{padding: '5px 5px'}}
                        onClick={e => {
                            e.preventDefault();
                            let lines = lineItems.map(l=>l);
                            lines.splice(ind, 1);
                            setLineItems(lines);
                        }}>X</button></label>
                    </li>
                )}
                <button className="btn bg-dark light-text" onClick={e => {
                    e.preventDefault();
                    let lines = lineItems.map(l=>l);
                    let line = {amount: 0, readOnly: 0}
                    if(payment?.ra_id) line.line_item_type_id=1;
                    lines.push(line);
                    setLineItems(lines);
                }}>Add Line Item</button>
                <label className="dark-text text-right">Total:
                {/* <input readOnly value={lineItems.map(l => l?.amount).reduce((acc, n) => {return acc + parseFloat(n)}, 0)} /> */}
                <input readOnly value={adjustment?.amount} />
                </label>

                <label className='dark-text text-left'>Status:</label>
                {/*<SearchSelect options={statuses.map(row => { return {label: row?.payment_status, value: row?.id} })}
                initialValue={{label: payment?.payment_status, value: payment?.payment_status_id}} updateParent={opt => {
                    opt ? setPayment({...payment, payment_status_id: opt?.value})
                    : setPayment({...payment, payment_status_id: null})
                }} />*/}

                <select form='EditPaymentForm' required className='contained' onChange={ e => setPayment({...payment, payment_status_id: e.target.value}) } value={payment?.payment_status_id}>
                    <option value={null} key={0}>*** SELECT PAYMENT STATUS ***</option>
                    {statuses.map( (row) => 
                        <option key={row?.id} value={row?.id} >{row?.payment_status}</option>
                    )}
                </select>

                <label className='dark-text text-left'>Notes:</label>
                <textarea form='EditPaymentForm' style={{minWidth: '80%', width: '80%', maxWidth: '80%'}} rows='10' value={payment?.notes} 
                onChange={e => setPayment({...payment, notes: e.target.value})}
                />

                <label className='dark-text text-left'>Reason for Adjustment:</label>
                <textarea form='EditPaymentForm' required style={{minWidth: '80%', width: '80%', maxWidth: '80%'}} rows='4' value={adjustment?.reason} 
                onChange={e => setAdjustment({...adjustment, reason: e.target.value})}
                />

                <br/>
                <input form='EditPaymentForm' className='btn bg-green light-text' type='submit' value={'Submit Changes'} />
                
                <br/><br/>
                <h2 className='dark-text'>Past Adjustments</h2>
                <TableList columnNames={['Date','Change','Reason','User']}
                columnWidths={['15%','15%','50%','20%']}
                entries={
                    adjustments.map(row => {
                        return [
                            formatDate(row?.created),
                            row?.amount > 0 ? `+${row?.amount}` : row?.amount,
                            row?.reason,
                            row?.user
                        ]
                    })
                }
                />
                <br/>
                <button className="btn bg-red light-text" onClick={cancelPayment}>Cancel Payment</button>
                </div>
            </fieldset>
        </div>
    )
}

export default EditPaymentForm;