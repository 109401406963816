import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AppContext from "../../AppContext";
import { formatDate, secureFetch } from "../../util/flip_utils";
import {CSVLink} from 'react-csv';
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import InfoButton from "../../components/infoButton";

const RmaReceiving = () => {
    let context = useContext(AppContext);

    // setting page variables
    let [report, setReport] = useState([]);
    let [reportType, setReportType] = useState('summary');
    let [interval, setInterval] = useState(7);
    let [subRep, setSubRep] = useState("")
    let [dateType, setDateType] = useState('interval')
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(null);
    let [source, setSource] = useState("B2C");
    let [orderBy, setOrderBy] = useState("submission_rep");

    // Show/hide info box function
    function infoFunction(event) {
        if(document.getElementById("infoBox").style.display == "none") {
            document.getElementById("infoBox").style.display = ""
        } else {
            document.getElementById("infoBox").style.display = "none"
        }
    }

    function changeDateTypeInterval(event){ //using this so that the report matches the default value when switching back to "interval" dateType
        setDateType('interval')
        setInterval(7)
    }


    function fetchReport() {
        secureFetch(context.server + '/reports/rma-receiving', {
            method: 'POST',
            credentials: 'include',
            headers: {'Authorization': `Bearer ${context?.accessToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({interval: interval, reportType: reportType, subRep: subRep, dateType: dateType, startDate: startDate, endDate: endDate, source: source, orderBy: orderBy})
        })
        .then(res => res.json())
        .then(data => {
            ///clean newline characters from report
            for(let r = 0; r < data?.length; r++) {
                let cols = Object.keys(data[r]);
                for(let c = 0; c < cols?.length; c++) {
                    let newrow = `${data[r][cols[c]]}`
                    newrow = newrow.replaceAll('\n', ' ');
                    newrow = newrow.replaceAll('\"', '\'');
                    //alert(newrow)
                    data[r][cols[c]] = newrow;
                }
            }

            setReport(data);
        })
    }


    useEffect(() => {
        fetchReport();
    }, [context, interval, reportType, subRep, dateType, startDate, endDate, source, orderBy])

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });



        
    return(
        <div>
            <div style={{padding: '30px 30px'}}>
           
                <div class = "container">
                    <h1 style={{padding: '5px 10px'}}>RMA Receiving</h1>
                    <InfoButton onClick = {infoFunction}/> 
                </div>

                <div className = "bg-light rounded  dark-text">
                    <div className="container">
                        
                        <div style={{padding: '15px 15px'}}>
                            <label className=" text-left"><input type='radio' checked={reportType=='summary'} onChange={e => e.target.checked==1 ? setReportType('summary') : null}/>&nbsp;Summary</label>
                            <label className=" text-left"><input type='radio' checked={reportType=='detail'} onChange={e => e.target.checked==1 ? setReportType('detail') : null} />&nbsp;Detail</label>
                        </div>
                        <label className=" text-left" for="source" style={{padding: '15px 15px'}} >Source:      
                                <select  name="catBrand" id="selectSource" onChange={e => setSource(e.target.value)}>
                                    <option selected value="B2C" >B2C</option>
                                    <option value="B2B" >B2B</option>
                                    <option  value="All" >All</option>
                                </select>
                        </label>
        
                        <div style={{padding: '15px 15px'}}>
                                <label className=" text-left"><input type='radio' checked={dateType=='interval'} onChange={e => e.target.checked==1 ? changeDateTypeInterval()  : null} />&nbsp;Interval</label>
                                <label className=" text-left"><input type='radio' checked={dateType=='customRange'} onChange={e => e.target.checked==1 ? setDateType('customRange') : null}/>&nbsp;Custom Date Range</label>
                            </div>
                            {dateType == 'interval' ? <> 
                                <label className=" text-left" for="interval" style={{padding: '15px 15px'}} >Report Interval:
                                    <select  name="inverval" id="selectInterval" onChange={e => setInterval(Number(e.target.value))}>
                                        <option selected value="7" >7 Days</option>
                                        <option value="14" >14 Days</option>
                                        <option value="30" >30 Days</option>
                                        <option value="60" >60 Days</option>
                                        <option value="120" >120 Days</option>
                                        <option value="365" >365 Days</option>
                                        <option value="">All Time (may take a few moments to load)</option>
                                    </select>
                                </label>

                            </> : <>
                                <div className='bg-lightest rounded' style={{padding: '30px 30px'}}>
                                    <div className='container'>
                                        <p className='dark-text contained' style={{maxWidth: '100px'}}>Start Date:</p>
                                        <DatePicker className='contained' value={startDate} onChange={setStartDate} locale='en-US'/>
                                    </div>
                                    <div className='container'>
                                        <p className='dark-text contained' style={{maxWidth: '100px'}}>End Date:</p>
                                        <DatePicker className='contained' value={endDate} onChange={setEndDate} locale='en-US'/>
                                    </div>
                                    <p><i>(Leave end date blank to get a one-day report)</i></p>
                                    {/* <CSVLink data={report} filename='submissions_progress_report.csv' className='btn dark-text'>Export Report to CSV</CSVLink> */}
                                    <br/>
                                </div>
                            </>}
                    </div>
                    <div  style={{padding: '10px 10px'}}>
                       <CSVLink data={report} filename='buyer_conversion.csv' className='btn light-text bg-dark'>Export Report to CSV</CSVLink>
                    </div>
                </div>

                <div id="infoBox" class = "bg-light" style = {{display:"none", padding: '20px 20px'}}> 
                    <p>This report looks at all RMAs received during the selected time frame. Note that, for the purposes of this report, an RMA is considered received once one or more items have been received.</p>
                    <br></br>
                    <p>For all item counts, the first number is the number of unique items and the number in parentheses is the total quanty of units.</p>
                    <br></br>
                    <p><b>Report Interval:</b> </p>
                    <p>This parameter filters for RMAs received within the given time interval.</p>
                    <br></br>
                    <p><b>Caveats: </b></p>
                    <p>- There seems to be an issue with the RMA creation date data. Some items are showing that they were received before the RMA was created. Sage suspects the RMA Created Date is being overwritten for some number of RMAs. He's investigating the issue.</p>
                    <p>- Rep shown is the rep assigned to the associated submissions</p>
                    <p>- Submission rep data not populated until late 12/22</p>
                    <p>- Receiving data was not collected until late 7/22</p>
                </div>

                
            </div>    
            
            <h2 className="light-text" style={{padding: '0px 0px 0px 10px'}}>Report at a glance:</h2>
                           
            {reportType=='summary' ? <>
            <br></br>
            <label className="light-text text-left" for="orderBy" style={{padding: '4px 15px'}} >Order By:      
                    <select  name="orderBy" id="selectOrderBy" onChange={e => setOrderBy(e.target.value)}>
                        <option selected value="submission_rep" >Rep</option>
                        <option value="received_rmas" >RMAs Received</option>
                        <option value="received_consign_items" >Consignment Items Received</option>
                        <option  value="received_purchase_items" >Purchase Items Received</option>
                        <option value="total_received_items" >Total Items Received</option>
                        <option value="ratio_sorting_ignore" >Consign:Purchase Ratio</option>   
                    </select>
                </label>
            <TableList columnNames={['Submission Rep', 'RMAs Received', 'Created to Received(days)', 'Consignment Items Received', 'Purchase Items Received', 'Total Items Received', 'Received List Value','Consign:Purchase Ratio']}
                    columnWidths={['12%','10%','20%','20%','20%','10%','10%','10%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.submission_rep,
                                row?.received_rmas,
                                row?.created_to_received_days,
                                <>{row?.unique_received_consign_items}&nbsp;&nbsp;&nbsp;{' ('+row?.received_consign_items+')'}</>,
                                <>{row?.unique_received_purchase_items}&nbsp;&nbsp;&nbsp;{' ('+row?.received_purchase_items+')'}</>,
                                <>{row?.unique_total_received_items}&nbsp;&nbsp;&nbsp;{' ('+row?.total_received_items+')'}</>,
                                currencyFormatter.format(row?.received_list_sum),
                                row?.consign_purchase_ratio
                            ]
                        })
                    } />
            </> : <>
            <label className="light-text text-left bg-" for="subRep" style={{padding: '4px 15px'}}  >Submission Rep:      
                        <select  name="subRep" id="selectSubRep" onChange={e => setSubRep(e.target.value)}>
                            <option selected value="" >Select an Option</option>
                            <option value="Ben Klenk" >Ben Klenk</option>
                            <option  value="Devin Schmidt" >Devin Schmidt</option>
                            <option value="Ian LaHood" >Ian Lahood</option>
                            <option value="Jason Manning" >Jason Manning</option>
                            <option value="Jerry Kall" >Jerry Kall</option>
                            <option value="Mario Alonzo" >Mario Alonzo</option>
                            <option value="Michael Horne" >Michael Horne</option>
                            <option value="Nicholas Lucini" >Nicholas Lucini</option>
                            <option value="Ryan Tarasen" >Ryan Tarasen</option>
                        </select>
                </label>
            <TableList columnNames={['Submission Rep', 'RMA Number', 'Created', 'Recieved List Value', 'First Item Received','Created to Received(days)']}
                    columnWidths={['20%','20%','20%','12%','20%','20%']}
                    entries={
                        report?.map(row => {
                            return [
                                row?.submission_rep,
                                <Link className='btn dark-text' to={{pathname: `/tpa-view/${row?.tpa_id}`, state: {tpa_id: row?.tpa_id}}}>{row?.tpa_number}</Link>,
                                row?.tpa_created,
                                currencyFormatter.format(row?.received_list_sum),
                                row?.first_item_received_date,
                                row?.created_to_received_days
                            ]
                        })
                    } />
            </>}       
            
        </div>
    );
}

export default RmaReceiving;